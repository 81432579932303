import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import orderLinkLogoExpanded from '../../assets/logo-expanded.svg';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import React, { useState } from 'react';
import authService from '../../services/auth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormHelperText, Stack } from '@mui/material';
import { convertTextToCamelCaseWord } from '../../utils/translations.utils';
import { getErrorMessage } from '../../utils/api.utils.ts';
import { LoadingButton } from '@mui/lab';

interface FormValues {
  email: string;
}

export default function ForgotPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);

  const schema = yup.object().shape({
    email: yup.string().email(t('invalidEmail', 'Invalid email')).required(t('emailRequired', 'Email is required')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormValues) => {
    const { email } = data;
    try {
      await authService.forgotPassword(email);
      setShowConfirmationMessage(true);
    } catch (err) {
      const message = getErrorMessage(err);
      const translationKey = convertTextToCamelCaseWord(message);

      setError('email', { message: t(translationKey, message) });
    }
  };

  const EmailSent = () => (
    <Stack direction={'column'} textAlign={'center'} spacing={1} mt={'2rem'}>
      <h1>{t('emailSent', 'Email Sent')}</h1>
      <p>
        {t('fewMinutes', 'It can take a few minutes to receive your password recovery link.')} <br />
        {t('contactAdministrator', 'If you do not receive this link, please contact your administrator.')}
      </p>
      <Link
        className="login-link"
        onClick={() => {
          navigate('/login');
        }}
        variant="body2"
      >
        {t('readyToSignIn', 'Ready to Sign in?')}
      </Link>
    </Stack>
  );

  const ForgotPasswordForm = () => (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box textAlign={'center'} my={'1rem'}>
        <h1>Password recovery</h1>
      </Box>
      <TextField
        required
        fullWidth
        id="email"
        sx={{ my: 1 }}
        label={t('emailAddress', 'Email Address')}
        {...register('email')}
        autoComplete="email"
        autoFocus
        className="login"
      />
      <FormHelperText error={true}>{errors['email']?.message}</FormHelperText>
      <Box textAlign={'center'}>
        <LoadingButton
          loading={isSubmitting}
          type="submit"
          color={'secondary'}
          variant="contained"
          sx={{ my: 1 }}
          fullWidth={true}
        >
          {t('sendEmail', 'Send Email')}
        </LoadingButton>
        <Link
          className="login-link"
          onClick={() => {
            navigate('/login');
          }}
          variant="body2"
        >
          {t('readyToSignIn', 'Ready to Sign in?')}
        </Link>
      </Box>
    </Box>
  );

  const getUi = () => {
    if (showConfirmationMessage) {
      return <EmailSent />;
    }

    return <ForgotPasswordForm />;
  };

  return (
    <Container maxWidth="xs" sx={{ height: '100%' }}>
      <Stack pt={'25vh'} direction={'column'} justifyContent={'center'}>
        <Box textAlign={'center'} height={'4rem'} pl={'1.5rem'} mb={1}>
          <img width={'100%'} height={'100%'} src={orderLinkLogoExpanded} alt="Company Logo" />
        </Box>
        {getUi()}
      </Stack>
    </Container>
  );
}
