export const OrderRequestStatus = {
  New: 'new',
  Opened: 'open',
  InReview: 'in-review',
  Complete: 'complete',
  ExportError: 'export-error',
  Rejected: 'rejected',
  Sent: 'sent',
};

export const GENERAL_ERROR_MESSAGE = 'Something went wrong';

export const Relation = {
  Inbox: 'inbox',
  BasicUser: 'basic-user',
  PopulatedUser: 'populated-user',
  DocumentRegions: 'document-regions',
  Currencies: 'currencies',
  RandomEmail: 'random-email',
  Queues: 'queues',
  Roles: 'roles',
  OrdersFromAllQueues: 'orders-all-queues',
  Tenant: 'tenant',
  PositionTypes: 'position-types',
  OrderRequestsByAutomationStatus: 'order-requests-by-automation-status',
  OrderRequest: 'order-request',
  OrderItems: 'order-items',
  Queue: 'queue',
  ReviewRequest: 'review-request',
  RoutingQueues: 'routing-queues',
  IncomingEmails: 'incoming-emails',
  RoutingQueue: 'routing-queue',
  OrderRequestByIncomingEmail: 'order-request-by-incoming-email',
  CategoriesForRoutingQueue: 'categories-for-routing-queue',
  OrderTexts: 'order-texts',
  OrderTextTypes: 'order-text-types',
  OrderRequestValidation: 'order-request-validation',
};

export const OrderRequestStatusPerTabValue: Record<string, string[]> = {
  all: [],
  open: [OrderRequestStatus.New, OrderRequestStatus.Opened, OrderRequestStatus.ExportError],
  review: [OrderRequestStatus.InReview],
  exported: [OrderRequestStatus.Complete],
  rejected: [OrderRequestStatus.Rejected],
  sent: [OrderRequestStatus.Sent],
};
