import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import AuthContext from '../../context/auth-context';
import type { User } from '../../types';
import LoadingSpinner from '../loading/loading.component';
import qs from 'qs';

interface ProtectedRouteProps {
  permissionFn?: (user: User) => boolean;
}

export default function ProtectedRoute({ permissionFn = () => true }: ProtectedRouteProps) {
  const { pathname, search } = useLocation();
  const authContext = useContext(AuthContext);

  if (authContext.isLoggedIn === null) {
    // User has not yet been authenticated - we don't know if they are logged in or not
    return <LoadingSpinner />;
  }

  if (!authContext.isLoggedIn) {
    const searchString = pathname ? qs.stringify({ redirect: encodeURIComponent(pathname + search) }) : undefined;

    return (
      <Navigate
        to={{
          pathname: '/login',
          search: searchString,
        }}
        replace
      />
    );
  }

  // We're waiting for the user to be loaded before we make a decision
  if (!authContext.user) {
    return <LoadingSpinner />;
  }

  // We know the user and they are logged in
  if (!permissionFn(authContext.user)) {
    return <Navigate to={'/'} replace />;
  }

  return <Outlet />;
}
