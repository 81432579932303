import { createContext } from 'react';
import type { Queue, User } from '../types';
import { noop } from 'lodash';

interface AuthContext {
  user: User | null;
  isLoggedIn: boolean | null;
  setSelectedUserQueueId: (queueId: number) => void;
  selectedUserQueue: Queue | null;
  logout: () => void;
}

const initialValue = {
  user: null,
  isLoggedIn: null,
  setSelectedUserQueueId: noop,
  selectedUserQueue: null,
  logout: noop,
};

const AuthenticationContext = createContext<AuthContext>(initialValue);
export default AuthenticationContext;
