import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import orderLinkLogoExpanded from '../../assets/logo-expanded.svg';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import authService from '../../services/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, FormHelperText, Stack } from '@mui/material';
import { convertTextToCamelCaseWord } from '../../utils/translations.utils';
import { ReactComponent as MicrosoftIcon } from '../../assets/microsoft.svg';
import { currentEnv } from '../../config';
import { getErrorMessage } from '../../utils/api.utils.ts';
import { verifyIfMSProviderIsSetup } from '../../services/api.ts';
import { LoadingButton } from '@mui/lab';

interface LoginProps {
  loginSuccess: (route?: string) => void;
}

interface FormValues {
  email: string;
  password: string;
}

export default function Login({ loginSuccess }: LoginProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const redirectUrl = decodeURIComponent(searchParams.get('redirect') || '/');

  const { baseUrl } = currentEnv;

  const schema = yup.object().shape({
    email: yup.string().email(t('invalidEmail', 'Invalid email')).required(t('emailRequired', 'Email is required')),
    password: yup.string().required(t('passwordRequired', 'Password is required')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [msBtnIsLoading, setMsBtnIsLoading] = useState(false);
  const [error, setError] = useState('');

  const onLogin = async (data: FormValues) => {
    const { email, password } = data;
    try {
      await authService.login(email, password);
      loginSuccess(redirectUrl);
    } catch (err: unknown) {
      const message = getErrorMessage(err);
      const translationKey = convertTextToCamelCaseWord(message);

      setError(t(translationKey, message));
    }
  };

  const onMicrosoftProviderClick = async () => {
    setMsBtnIsLoading(true);

    try {
      const enabled = await verifyIfMSProviderIsSetup();

      if (!enabled) return setError(t('microsoftProviderNotEnabled', 'Microsoft provider is not enabled'));

      window.location.href = `${baseUrl}/connect/microsoft`;
    } catch (err) {
      return setError(getErrorMessage(err));
    } finally {
      setMsBtnIsLoading(false);
    }
  };

  return (
    <Container maxWidth="xs" sx={{ height: '100%' }}>
      <Stack pt={'25vh'} direction={'column'} justifyContent={'center'}>
        <Box textAlign={'center'} height={'4rem'} pl={'1.5rem'} mb={1}>
          <img width={'100%'} height={'100%'} src={orderLinkLogoExpanded} alt="Company Logo" />
        </Box>
        <Box component="form" onSubmit={handleSubmit(onLogin)} noValidate>
          <Box textAlign={'center'} my={'1rem'}>
            <h1>Welcome to order.link!</h1>
          </Box>
          {error && (
            <FormHelperText error={true} sx={{ textAlign: 'center' }}>
              {error}
            </FormHelperText>
          )}
          <TextField
            required
            fullWidth
            id="email"
            sx={{ my: 1 }}
            className="login"
            label={t('emailAddress', 'Email Address')}
            autoComplete="email"
            autoFocus
            {...register('email', { required: true })}
          />
          {errors.email?.message && <FormHelperText error={true}>{errors['email']?.message}</FormHelperText>}

          <TextField
            required
            fullWidth
            label={t('password', 'Password')}
            type="password"
            id="password"
            sx={{ my: 1 }}
            className="login"
            inputProps={{ 'aria-label': 'password' }}
            autoComplete="current-password"
            {...register('password', { required: true })}
          />
          {errors.password?.message && <FormHelperText error={true}>{errors['password']?.message}</FormHelperText>}

          <Box textAlign={'center'}>
            <Button type="submit" color={'secondary'} variant="contained" sx={{ my: 1 }} fullWidth={true}>
              {t('login', 'Login')}
            </Button>
            <Link
              className="login-link"
              onClick={() => {
                navigate('/forgot-password');
              }}
              variant="body2"
            >
              {t('forgotPassword', 'Forgot password?')}
            </Link>
          </Box>
        </Box>
        <Divider sx={{ my: 2.5 }}>{t('dividerOr', 'or')}</Divider>
        <LoadingButton
          loading={msBtnIsLoading}
          sx={{ backgroundColor: '#fff' }}
          onClick={onMicrosoftProviderClick}
          variant="outlined"
          fullWidth={true}
          startIcon={<MicrosoftIcon style={{ height: '2.2rem', width: '2.2rem' }} />}
        >
          Microsoft
        </LoadingButton>
      </Stack>
    </Container>
  );
}
