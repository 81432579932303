import axios from 'axios';
import { createAPISearchFilter } from '../utils/strapi.utils';
import { ORDERS_SEARCH_FILTER_COLUMNS } from '../utils/constants.utils';
import {
  AnalyticsEvent,
  Customer,
  Message,
  OrderItem,
  OrderRequest,
  OrderText,
  Pagination,
  Product,
  RequestCategory,
  Role,
  SearchResults,
  User,
  ValidationReport,
} from '../types';
import { config, getData } from '../utils/api.utils';
import qs from 'qs';
import { get } from 'lodash';
import { create, windowScheduler } from '@yornaath/batshit';

const ORDER_REQUEST_POPULATION = [
  'soldToPartyCustomer',
  'shipToPartyCustomer',
  'billToPartyCustomer',
  'customerRecommendations',
  'assignees',
  'messages',
  'messages.creator',
  'requestCategory',
  'deliveryBlock',
  'incoterm',
  'paymentTerm',
];

const ORDER_ITEM_POPULATION = ['product', 'positionType', 'productMatchReference'];

interface HandleAuth {
  identifier: string;
  password: string;
}

interface CreateOrderItem {
  orderItem: Partial<OrderItem>;
  orderRequestId: number;
}

interface GetOrdersByQueue {
  start: number;
  limit: number;
  queueId: number;
  statusFilter: string[];
  categoryFilter: string[];
  searchFilter: string;
  assigneeFilter: string[] | null;
  getRequestCategory?: boolean;
  isDemo?: boolean;
}

interface CreateQueue {
  name: string;
  email: string;
  documentRegion: string | number;
  currency: string | number;
  users: (string | number)[];
  tenant: string | number;
}

export interface UpdateOrderRequest {
  orderRequestId: number;
  data: Record<string, any>;
}

interface UpdateRecord {
  id: number;
  data: Record<string, any>;
}

type ValidationRequest = Pick<OrderRequest, 'id' | 'requestedDeliveryDate'> & { confirmedById: number };

export const generateRandomEmail = async () => (await axios.get('/queues/create-email', config())).data;

export const getUserData = async () => {
  const query = qs.stringify({
    populate: ['tenant', 'tenant.queues', 'queues', 'queues.currency', 'queues.documentRegion', 'queues.users'],
  });

  const res = await axios.get(`/users/me?${query}`, config());
  return res.data;
};

export const verifyIfMSProviderIsSetup = async () => {
  const { data } = await axios.get(`/public/providers/microsoft`);

  return data.data.enabled;
};

export const getTokenFromCallback = async (accessToken: string) =>
  await axios.get(`/auth/microsoft/callback?access_token=${accessToken}`);

export const postAuth = async ({ identifier, password }: HandleAuth) =>
  await axios.post(`/auth/local`, { identifier, password });

export const postForgotPassword = async (email: string) => await axios.post(`/auth/forgot-password`, { email });

export const postResetPassword = async (code: string, password: string, passwordConfirmation: string) =>
  await axios.post('/auth/reset-password', { code, password, passwordConfirmation });

export const getCurrencies = async () => {
  const { data } = await axios.get(`/currencies`, config());
  return getData(data);
};

export const deleteOrderItem = (id: number): Promise<OrderItem> =>
  axios.delete(`/order-items/${id}`, config()).then(({ data }) => getData(data));

export const putOrderItemPositionReferences = async (
  partialOrderItems: Pick<OrderItem, 'id' | 'positionReference'>[]
) => {
  const res = await axios.put(
    `/position-references`,
    {
      data: partialOrderItems,
    },
    config()
  );

  return get(res, 'data.data', []) as typeof partialOrderItems;
};

export const updateOrderItem = async (orderItem: Partial<OrderItem>) => {
  const query = qs.stringify(
    {
      populate: ORDER_ITEM_POPULATION,
    },
    {
      encodeValuesOnly: true,
    }
  );

  const { data } = await axios.put(
    `/order-items/${orderItem.id}?${query}`,
    {
      data: orderItem,
    },
    config()
  );

  return getData(data);
};

export const createOrderItem = async ({ orderItem, orderRequestId }: CreateOrderItem) => {
  const query = qs.stringify(
    {
      populate: ORDER_ITEM_POPULATION,
    },
    {
      encodeValuesOnly: true,
    }
  );

  const { data } = await axios.post(
    `/order-items?${query}`,
    {
      data: {
        ...orderItem,
        orderRequest: orderRequestId,
      },
    },
    config()
  );

  return getData<OrderItem>(data);
};

export const sendValidatedOrderRequestToServer = async (orderRequest: Partial<ValidationRequest>) => {
  const { data } = await axios.post(`/order-requests/export`, { data: orderRequest }, config());
  return data as Partial<OrderRequest>;
};

export const updateOrderRequest = async ({ orderRequestId, data }: UpdateOrderRequest) => {
  const query = qs.stringify(
    {
      populate: ORDER_REQUEST_POPULATION,
    },
    {
      encodeValuesOnly: true,
    }
  );

  const res = await axios.put(
    `/order-requests/${orderRequestId}?${query}`,
    {
      data,
    },
    config()
  );
  return getData(res).data;
};

export const getDashboardOrderRequests = async ({
  start,
  limit,
  queueId,
  statusFilter,
  categoryFilter,
  searchFilter,
  assigneeFilter,
  getRequestCategory,
  isDemo,
}: GetOrdersByQueue) => {
  const searchFilterQuery = createAPISearchFilter(searchFilter, ORDERS_SEARCH_FILTER_COLUMNS);
  const query = qs.stringify(
    {
      fields: [
        'id',
        'status',
        'attachmentUrls',
        'createdAt',
        'originalFromEmail',
        'exportDate',
        'fromName',
        'fromEmail',
        'originalFromName',
        'orderNumber',
        'subject',
        'quotationNumber',
        'requestCategoryConfidenceScore',
      ],
      filters: {
        queue: {
          id: {
            $eq: queueId,
          },
        },
        assignees: assigneeFilter && (!getRequestCategory || isDemo) ? { id: { $eq: assigneeFilter } } : undefined,
        requestCategory: categoryFilter.length > 0 ? { id: { $in: categoryFilter } } : undefined,
        status: statusFilter.length > 0 ? { $in: statusFilter } : undefined,
      },
      pagination: {
        start: start,
        limit: limit,
      },
      sort: ['createdAt:desc'],
      populate: [
        'soldToPartyCustomer',
        'assignees',
        'messages',
        'messages.creator',
        'messages.recipients',
        'related',
        getRequestCategory || isDemo ? 'requestCategory' : '',
      ].filter(Boolean),
    },
    {
      encodeValuesOnly: true,
    }
  );

  const { data } = await axios.get(`/order-requests?${query}${searchFilterQuery}`, config());

  return { results: data.data as OrderRequest[], pagination: data.meta.pagination as Pagination };
};

export const getOrderRequestByIdAndQueueId = async (id: number, queueId: number) => {
  const query = qs.stringify(
    {
      filters: {
        id: {
          $eq: id,
        },
        queue: {
          id: {
            $eq: queueId,
          },
        },
      },
      populate: ORDER_REQUEST_POPULATION,
    },
    {
      encodeValuesOnly: true,
    }
  );

  const res = await axios.get(`/order-requests/?${query}`, config());
  const data = getData(res).data;

  return data.length > 0 ? data[0] : null;
};

export const getOrderRequestValidation = async (id: number) => {
  const { data } = await axios.get(`/order-requests/${id}/validation`, config());

  return data as ValidationReport;
};

export const orderRequestDashboardValidation = create<ValidationReport[], number, ValidationReport | null>({
  fetcher: async (ids: number[]) => {
    const query = qs.stringify({
      ids: ids,
    });
    const { data } = await axios.get(`/dashboard/order-requests/validation?${query}`, config());

    return data;
  },
  resolver: (items: ValidationReport[], id: number) => items.find((item) => item.orderRequest.id === id) || null,
  scheduler: windowScheduler(350),
});

export const deleteOrderRequest = (id: number): Promise<OrderRequest> =>
  axios.delete(`order-requests/${id}`, config()).then(({ data }) => getData(data));

export const getAllRoles = async (): Promise<Role[]> =>
  (await axios.get(`/users-permissions/roles`, config())).data.roles;

export const fetchQueuesByTenantId = async (tenantId: number) => {
  const query = qs.stringify({
    filters: {
      tenant: {
        id: tenantId,
      },
    },
    populate: ['currency', 'documentRegion'],
  });
  const { data } = await axios.get(`/queues?${query}`, config());
  return getData(data);
};

export const fetchTenantById = async (tenantId: number) => {
  const { data } = await axios.get(`/tenants/${tenantId}`, config());
  return getData(data);
};

export const createQueue = async ({ name, email, documentRegion, currency, users, tenant }: CreateQueue) => {
  await axios.post(
    '/queues',
    {
      data: {
        name,
        email,
        documentRegion,
        currency,
        users,
        tenant,
      },
    },
    config()
  );
};

export const fetchTenantData = async (tenantId: number, populateFields: string[]) => {
  const query = qs.stringify({
    populate: populateFields,
  });

  const { data } = await axios.get(`/tenants/${tenantId}?${query}`, config());
  return getData(data);
};

export const getAllUsersData = async (tenantId: number): Promise<User[]> =>
  (await axios.get(`/users?filters[tenant][id]=${tenantId}&populate=*`, config())).data;

interface UpdateProps {
  id: number;
  label: string;
  value: string;
}

export const updateUser = async ({ id, label, value }: UpdateProps) =>
  await axios.put(
    `/users/${id}`,
    {
      [label]: value,
    },
    config()
  );

export const updateQueue = async ({ id, data }: UpdateRecord) => {
  await axios.put(
    `/queues/${id}`,
    {
      data,
    },
    config()
  );
};

export const getOrderItemsByOrderRequestId = async (orderRequestId: number) => {
  const query = qs.stringify(
    {
      filters: {
        orderRequest: {
          id: {
            $eq: orderRequestId,
          },
        },
      },
      populate: ORDER_ITEM_POPULATION,
      pagination: {
        limit: -1,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  const res = await axios.get(`/order-items?${query}`, config());

  return getData(res).data as OrderItem[];
};

export const getOrderItemAndOrderRequest = async (orderItemId: number) => {
  const query = qs.stringify({
    fields: ['id', 'positionReference'],
    populate: {
      orderRequest: {
        fields: ['id', 'subject', 'orderNumber'],
        populate: ['queue'],
      },
    },
  });

  const { data } = await axios.get(`/order-items/${orderItemId}?${query}`, config());

  return data.data as OrderItem;
};

export const getDefaultShipToPartyCustomer = async (soldToPartyId: number) => {
  const { data } = await axios.get(`/customers/default-ship-to-Party/${soldToPartyId}`, config());

  return data.data;
};

export const deleteOrderText = async (id: number) => await axios.delete(`/order-texts/${id}`, config());

export const updateOrderText = async (orderText: Partial<OrderText>) => {
  const query = qs.stringify({ populate: '*' }, { encodeValuesOnly: true });

  const { data } = await axios.put(
    `/order-texts/${orderText.id}?${query}`,
    {
      data: orderText,
    },
    config()
  );

  return getData(data);
};

export const getOrderTextsByOrderRequestId = async (orderRequestId: number) => {
  const query = qs.stringify(
    {
      filters: {
        orderRequest: {
          id: {
            $eq: orderRequestId,
          },
        },
      },
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    }
  );

  const res = await axios.get(`/order-texts?${query}`, config());

  return getData(res).data as OrderText[];
};

export const createOrderText = async (orderText: Omit<OrderText, 'id'>) => {
  const { data } = await axios.post(`/order-texts`, { data: orderText }, config());
  return getData<OrderText>(data);
};

export const deleteQueue = async (id: number) => await axios.delete(`/queues/${id}`, config());

export const getQueueById = async (id: number) => {
  const query = qs.stringify(
    {
      filters: {
        id: {
          $eq: id,
        },
      },
      populate: ['documentRegion', 'currency', 'users'],
    },
    {
      encodeValuesOnly: true,
    }
  );

  const res = await axios.get(`/queues?${query}`, config());
  const data = getData(res).data;

  return data.length > 0 ? data[0] : null;
};

export const createAnalyticsEvent = (data: AnalyticsEvent) => axios.post(`/analytics-events`, { data }, config());

export const getReviewRequestEmailThread = async (orderRequestId: number) => {
  const query = qs.stringify(
    {
      filters: {
        orderRequest: {
          id: {
            $eq: orderRequestId,
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  const res = await axios.get(`/review-requests?${query}`, config());
  const data = getData(res).data;

  return data.length > 0 ? data[0] : null;
};

interface CreateMessage {
  message: Partial<Message>;
  orderRequestId: number;
}

export const createMessage = async ({ message, orderRequestId }: CreateMessage) => {
  const query = qs.stringify(
    {
      populate: ['creator'],
    },
    {
      encodeValuesOnly: true,
    }
  );

  const { data } = await axios.post(
    `/messages?${query}`,
    {
      data: {
        ...message,
        orderRequest: orderRequestId,
      },
    },
    config()
  );

  return getData<Message>(data);
};

interface MarkMessagesAsReadByUserProps {
  userId: number;
  messageIds: number[];
}

export const markMessagesAsRead = async ({ userId, messageIds }: MarkMessagesAsReadByUserProps) => {
  const query = qs.stringify({
    encodeValuesOnly: true,
  });

  const res = await axios.put(
    `/messages/markAsRead?${query}`,
    {
      data: { userId, messageIds },
    },
    config()
  );
  return getData(res).data;
};

interface SendEmailChatProps {
  messageId: number;
  url: string;
}

export const sendEmailChat = async ({ messageId, url }: SendEmailChatProps) => {
  return (await axios.post(`/messages/sendEmailChat`, { data: { messageId, url } }, config())).data;
};

export const downloadEML = async ({ id }: Partial<OrderRequest>) => {
  return await axios.get(`/order-requests/downloadEml/${id}`, {
    ...config(),
    responseType: 'blob',
  });
};

export const getDeliveryBlocks = async () => {
  const { data } = await axios.get(`/delivery-blocks?pagination[limit]=-1`, config());

  return getData(data) || [];
};

export const getIncoterms = async () => {
  const { data } = await axios.get(`/incoterms?pagination[limit]=-1`, config());

  return getData(data) || [];
};

export const getPaymentTerms = async () => {
  const { data } = await axios.get(`/payment-terms?pagination[limit]=-1`, config());

  return getData(data) || [];
};

export const getRequestCategories = async () => {
  const query = qs.stringify(
    {
      fields: ['label', 'color'],
    },
    { encodeValuesOnly: true }
  );
  const { data } = await axios.get(`/request-categories?${query}`, config());

  return (getData(data) || []) as Pick<RequestCategory, 'label' | 'id' | 'color'>[];
};

export const postCustomerSearch = async (searchQuery: string, options: Record<string, any>) => {
  const { data } = await axios.post(
    `/search/customers`,
    {
      data: {
        ...options,
        query: searchQuery,
      },
    },
    config()
  );

  return data as SearchResults<Customer>;
};

export const postProductSearch = async (searchQuery: string, options: Record<string, any>) => {
  const { data } = await axios.post(
    `/search/products`,
    {
      data: {
        ...options,
        query: searchQuery,
      },
    },
    config()
  );

  return data as SearchResults<Product>;
};

export const postFreeTextOrderRequest = async (postData: { subject: string; message: string; userName: string }) => {
  const { subject, message, userName } = postData;
  const { data } = await axios.post(`/email`, { id: 'manual', subject, plainTextBody: message, userName }, config());

  return data.data;
};

export const getQueueStatusCounts = async (queueId: number, filters: Record<string, number[]>) => {
  const query = qs.stringify({ filters }, { encodeValuesOnly: true });
  const { data } = await axios.get(`/queues/${queueId}/status-counts?${query}`, config());

  return data.data as Record<string, number>;
};

export const getSurveyModel = async () => {
  const { data } = await axios.get(`/survey/model`, config());

  return data.data;
};

export const postSurveyResults = async (postData: Record<string, any>) => {
  const { data } = await axios.post(`/survey-results`, { data: postData }, config());

  return data.data;
};
