export enum DocumentType {
  order = 'order',
  classification = 'classification',
}

export enum OrderTextTypeScope {
  item = 'item',
  header = 'header',
}

export enum Status {
  new = 'new',
  open = 'open',
  inReview = 'in-review',
  complete = 'complete',
  sent = 'sent',
  exportError = 'export-error',
  rejected = 'rejected',
}

export enum ExportResponseStatus {
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export enum Events {
  click = 'click',
  fieldChange = 'fieldChange',
  openExternal = 'openExternal',
  sessionDuration = 'sessionDuration',
}

export enum ItemType {
  standard = 'standard',
  optional = 'optional',
  alternative = 'alternative',
}

export enum CustomerType {
  SOLD_TO_PARTY_CUSTOMER = 'soldToPartyCustomer',
  SHIP_TO_PARTY_CUSTOMER = 'shipToPartyCustomer',
  BILL_TO_PARTY_CUSTOMER = 'billToPartyCustomer',
}

export enum Severity {
  Error = 'error',
  Warning = 'warning',
  Suggestion = 'suggestion',
}
