import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Route,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Shadow from './components/shadow-dom/shadow-dom.component';
import { createTheme, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { initLocize } from './i18n';
import * as Sentry from '@sentry/react';
import { HttpClient as HttpClientIntegration } from '@sentry/integrations';
import Hotjar from '@hotjar/browser';
import ErrorFallback from './components/error-fallback/error-fallback';
import { currentEnv } from './config';
import { SnackbarProvider } from 'notistack';
import { getErrorMessage } from './utils/api.utils.ts';
import { EventEmitter } from './services/EventEmitter.ts';

const { baseUrl } = currentEnv;

Sentry.init({
  enabled: import.meta.env.PROD,
  dsn: 'https://0ade76a746eb4560a012016708b4cb3c@o4505598419533824.ingest.sentry.io/4505598440767488',
  tunnel: baseUrl + '/sentry-tunnel',
  release: import.meta.env.VITE_APP_VERSION,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', /^https:\/+.order.link/],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new HttpClientIntegration({
      failedRequestStatusCodes: [400, [404, 599]],
      failedRequestTargets: [/^https:\/+.order.link/],
    }),
  ],
  tracesSampleRate: 0.4,
});
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

initLocize();

const siteId = import.meta.env.VITE_HOTJAR_SITEID;
const hotjarVersion = 6;
if (siteId) {
  Hotjar.init(Number(siteId), hotjarVersion);
}

const theme = createTheme({
  palette: {
    primary: { main: '#75BCFF' },
    secondary: { main: '#71e25a' },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          background-color: #f8f8f9;
          font-family: MoriRegular, sans-serif;
          line-height: normal;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
          whiteSpace: 'nowrap',
        },
        contained: {
          color: '#fff',
        },
        outlinedPrimary: {
          color: '#000',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontFamily: 'MoriBold, sans-serif',
          fontSize: '1em',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
        input: {
          padding: '8px 12px',
        },
        multiline: {
          padding: 0,
        },
      },
    },
  },
  typography: {
    fontFamily: 'MoriRegular, sans-serif',
  },
});

const mutationCache = new MutationCache({
  onError: (error) => {
    const message = getErrorMessage(error);
    EventEmitter.dispatch('mutation-error', { message, error });
  },
});

const queryCache = new QueryCache({
  onError: (error) => {
    const message = getErrorMessage(error);
    EventEmitter.dispatch('query-error', { message, error });
  },
});

const queryClient = new QueryClient({
  mutationCache,
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <SentryRoutes>
            <Route
              path="*"
              element={
                <Sentry.ErrorBoundary fallback={ErrorFallback}>
                  <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <App />
                  </SnackbarProvider>
                </Sentry.ErrorBoundary>
              }
            />
          </SentryRoutes>
        </BrowserRouter>
        <Shadow>
          <ReactQueryDevtools position={'bottom-right'} initialIsOpen={false} />
        </Shadow>
      </QueryClientProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
