import auth from '../services/auth.ts';
import { Axios } from 'axios';
import { StrapiError } from '../types';
import { get } from 'lodash';

export const config = () => {
  const token = auth.getToken();

  return { headers: { Authorization: `Bearer ${token}` } };
};

export const addAppVersionInterceptor = (axiosInstance: Axios, callback?: (apiVersion?: string) => void) => {
  axiosInstance.interceptors.response.use((response) => {
    const apiVersion = response.headers['x-app-version'];

    if (callback) {
      callback(apiVersion);
    }

    return response;
  });
};

export const getErrorMessage = (error: any) => {
  if (error.response) {
    const data = error.response.data as StrapiError;

    if (data && data.error) {
      return data.error.message;
    }
  }

  return error.message;
};

export const getData = <T = any>(response: unknown): T => get(response, 'data');
