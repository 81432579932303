type tFunc = (key: string, initialValue: string) => string;

export const translateDropdowns = (t: tFunc, dropDownData: Record<string, string>) => {
  const dropwDownDataWrapedForLocize: Record<string, string> = {};
  Object.keys(dropDownData).forEach((key: string) => {
    dropwDownDataWrapedForLocize[key] = t(dropDownData[key].toLowerCase(), dropDownData[key]);
  });
  return dropwDownDataWrapedForLocize;
};

export const convertTextToCamelCaseWord = (text: string) => {
  const words = text.toLowerCase().split(' ');
  for (let i = 1; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  return words.join('');
};

export const getTranslatedValidationMessage = (t: tFunc, code: number, message: string) => {
  return t(`validation.${code}`, message);
};
