import { ReactNode, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';

interface ShadowProps {
  mode?: string;
  children: ReactNode;
}

function Shadow({ mode = 'open', children }: ShadowProps) {
  const [shadowRoot, setShadowRoot] = useState(null);

  const callbackRef = useCallback(
    (node: any) => {
      if (node !== null && shadowRoot === null) {
        setShadowRoot(node.attachShadow({ mode: mode }));
      }
    },
    [mode, shadowRoot]
  );

  return <div ref={callbackRef}>{shadowRoot && createPortal(<>{children}</>, shadowRoot)}</div>;
}

export default Shadow;
