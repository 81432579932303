import { Box, Container, Stack, Typography } from '@mui/material';
import React from 'react';
import Logo from '../../assets/logo-expanded.svg';

interface ErrorFallbackProps {
  error: Error;
  errorCounter?: Record<string, number>;
}

const errors: Record<string, number> = {};

function ErrorFallback({ error, errorCounter = errors }: ErrorFallbackProps) {
  const errorMessage = error.message;

  if (
    errorMessage.includes('Unable to preload') ||
    errorMessage.includes('error loading dynamically imported module')
  ) {
    errorCounter[errorMessage] = (errorCounter[errorMessage] || 0) + 1;

    if (errorCounter[errorMessage] < 3) {
      window.location.reload();
    }
  }

  return (
    <Container maxWidth={'md'}>
      <Stack direction="row" justifyContent="center" alignItems="center" sx={{ width: 1, height: '80vh' }}>
        <Stack direction="column" spacing={2} alignItems={'center'}>
          <Box maxWidth={'200px'}>
            <img width={'100%'} src={Logo} alt={'order.link'} />
          </Box>
          <Box textAlign={'center'}>
            <Typography variant={'h1'}>An error occurred</Typography>
            <Typography>
              We are sorry about this. <br />
              Please refresh the page.
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
}

export default ErrorFallback;
