import { useTranslation } from 'react-i18next';
import './overlay.styles.scss';

const OverlayComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="overlay">
      <h3 className="overlay-text">{t('smallBrowser', 'Your browser is too small')}</h3>
      <p className="overlay-text">
        {t(
          'resizeBrowser',
          'Resize your browser to be at least 900px wide and 500px tall to continue using order.link'
        )}
      </p>
    </div>
  );
};
export default OverlayComponent;
