import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import React, { lazy, useEffect, useState } from 'react';
import './App.scss';
import './styles/ag-grid.scss'; // REQUIRED: Import ag-grid styles
import NavBar from './components/nav-bar/nav-bar.component';
import ProtectedRoute from './components/protected-route/protected-route.component';
import Login from './pages/login/login.component';
import { useTranslation } from 'react-i18next';
import OverlayComponent from './components/overlay/overlay.component';
import axios from 'axios';
import { currentEnv } from './config';
import authService from './services/auth';
import AuthenticationContext from './context/auth-context';
import ResetPassword from './pages/reset-password/reset-password.component';
import type { User } from './types';
import ForgotPassword from './pages/forgot-password/forgot-password.component';
import { findQueueById } from './utils/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Relation } from './constants';
import { getUserData } from './services/api';
import { useLocalStorage } from './hooks/use-local-storage';
import { IntlProvider } from './lib/intl';
import { useWindowHeight, useWindowWidth } from './hooks/use-window-size';
import HomeRedirect from './pages/home/HomeRedirect';
import AuthMicrosoftFunction from './pages/auth/auth-microsoft.component';
import SuspenseLoading from './components/suspense-loading/suspense-loading';
import BrowserTitle from './components/browser-title/browser-title';
import { Box, Button } from '@mui/material';
import { To } from '@remix-run/router';
import { hasAuthorizationError } from './utils/auth.utils.ts';
import { addAppVersionInterceptor } from './utils/api.utils.ts';
import { EventEmitter } from './services/EventEmitter.ts';
import { useSnackbar } from 'notistack';
import * as Sentry from '@sentry/react';

// lazy loaded components
const OrderRequestDashboardContainer = lazy(
  () => import('pages/order-request-dashboard/order-requests-dashboard-container.tsx')
);
const OrderRequestDashboard = lazy(() => import('pages/order-request-dashboard/order-requests-dashboard.tsx'));
const OrderRequest = lazy(() => import('./components/order-requests/order-request.tsx'));
const Settings = lazy(() => import('pages/settings/settings.component'));
const Queues = lazy(() => import('pages/queues/queues.component'));
const Dashboard = lazy(() => import('pages/dashboard/dashboard.component'));
const SalesIntelligence = lazy(() => import('pages/sales-intelligence/sales-intelligence.tsx'));

const { baseUrl } = currentEnv;
axios.defaults.baseURL = baseUrl;

const frontendAppVersion = import.meta.env.VITE_APP_VERSION;

addAppVersionInterceptor(axios, (serverAppVersion) => {
  if (serverAppVersion && frontendAppVersion && frontendAppVersion !== serverAppVersion) {
    EventEmitter.dispatch('app-version-changed', { serverAppVersion, frontendAppVersion });
  }
});

export default function App() {
  const { enqueueSnackbar } = useSnackbar();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const screenHeight = useWindowHeight();
  const screenWidth = useWindowWidth();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [selectedUserQueueId, setSelectedUserQueueId] = useLocalStorage<number | null>('userQueue', null);
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const { data: userData = null, error } = useQuery<User>([Relation.BasicUser], getUserData, {
    enabled: !!isLoggedIn,
  });

  const selectedUserQueue = findQueueById(selectedUserQueueId, userData?.queues || []);
  const isAuthScreen = pathname === '/login' || pathname === '/forgot-password' || pathname === '/reset-password';
  const showScreenSizeOverlay = screenWidth < 900 || screenHeight < 500;

  const logout = () => {
    queryClient.clear();
    setSelectedUserQueueId(null);
    setIsLoggedIn(false);
    authService.logout();
  };

  const handleLoginSuccess = (route?: To) => {
    setIsLoggedIn(true);
    navigate(route || '/', { replace: true });
  };

  useEffect(() => {
    if (userData) {
      Sentry.setUser({ email: userData.email, id: userData.id, username: userData.username });
    } else {
      Sentry.setUser(null);
    }
  }, [userData]);

  useEffect(() => {
    function handleAppVersionChanged() {
      enqueueSnackbar(t('newVersionAvailable', 'A new version of order.link is available.'), {
        action: () => (
          <Button onClick={() => window.location.reload()} color="inherit" size="small">
            {t('reload', 'Reload')}
          </Button>
        ),
        preventDuplicate: true,
        key: 'new-app-version',
        persist: true,
        variant: 'info',
      });
    }

    EventEmitter.subscribe('app-version-changed', handleAppVersionChanged);

    return () => {
      EventEmitter.unsubscribe('app-version-changed', handleAppVersionChanged);
    };
  }, [t, enqueueSnackbar]);

  useEffect(() => {
    function handleError({ message }: { message: string; error: any }) {
      enqueueSnackbar(message, {
        preventDuplicate: true,
        variant: 'error',
      });
    }

    EventEmitter.subscribe('mutation-error', handleError);
    EventEmitter.subscribe('query-error', handleError);

    return () => {
      EventEmitter.unsubscribe('mutation-error', handleError);
      EventEmitter.unsubscribe('query-error', handleError);
    };
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (searchParams.has('token')) {
      searchParams.delete('token');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  if (isLoggedIn === null) {
    if (searchParams.has('token')) {
      authService.setToken(searchParams.get('token') || '');
    }

    if (authService.isTokenValid()) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  } else if (isLoggedIn && (!authService.isTokenValid() || hasAuthorizationError(error))) {
    // token is expired
    enqueueSnackbar(t('sessionExpired', 'Your session has expired. Please log in again.'), {
      variant: 'info',
    });
    logout();
  }

  return (
    <IntlProvider locale={i18n.language}>
      <BrowserTitle title={'order.link'}>
        <html lang={i18n.language} />
      </BrowserTitle>
      <AuthenticationContext.Provider
        value={{
          user: userData,
          selectedUserQueue: selectedUserQueue,
          setSelectedUserQueueId,
          isLoggedIn,
          logout,
        }}
      >
        <Box height={'100vh'}>
          {isLoggedIn && <NavBar />}
          {showScreenSizeOverlay && <OverlayComponent />}

          <Box
            className={'app-content-container'}
            height={'100%'}
            component={'main'}
            marginLeft={isAuthScreen ? '0px' : '65px'}
          >
            <Routes>
              {/* Catch all route */}
              <Route path="*" element={<HomeRedirect />} />
              <Route path="/login" element={<Login loginSuccess={handleLoginSuccess} />} />
              <Route
                path="/auth/microsoft/callback"
                element={<AuthMicrosoftFunction loginSuccess={handleLoginSuccess} />}
              />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route element={<ProtectedRoute />}>
                <Route
                  path="/queues"
                  element={
                    <SuspenseLoading>
                      <Queues />
                    </SuspenseLoading>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <SuspenseLoading>
                      <Dashboard />
                    </SuspenseLoading>
                  }
                />
                <Route
                  path="/sales-intelligence"
                  element={
                    <SuspenseLoading>
                      <SalesIntelligence />
                    </SuspenseLoading>
                  }
                />
                <Route
                  path="/order-requests"
                  element={
                    <SuspenseLoading>
                      <OrderRequestDashboardContainer />
                    </SuspenseLoading>
                  }
                >
                  <Route
                    path={':queueId'}
                    element={
                      <SuspenseLoading>
                        <OrderRequestDashboard />
                      </SuspenseLoading>
                    }
                  >
                    <Route
                      path={':orderRequestId'}
                      element={
                        <SuspenseLoading>
                          <OrderRequest />
                        </SuspenseLoading>
                      }
                    />
                  </Route>
                </Route>
                <Route
                  path="/settings"
                  element={
                    <SuspenseLoading>
                      <Settings />
                    </SuspenseLoading>
                  }
                />
              </Route>
            </Routes>
          </Box>
        </Box>
      </AuthenticationContext.Provider>
    </IntlProvider>
  );
}
