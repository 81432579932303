import { useEffect, useRef } from 'react';
import { isEqual as _isEqual } from 'lodash';

// Taken from: https://usehooks.com/useMemoCompare/

export function useMemoCompare<T>(next: T, compare: (prev: T, next: T) => boolean = _isEqual) {
  const previousRef = useRef<T>();
  const previous = previousRef.current;

  const isEqual = compare(previous as T, next);

  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });

  return isEqual ? previous : next;
}
