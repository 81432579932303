import jwt_decode from 'jwt-decode';
import { getTokenFromCallback, postAuth, postForgotPassword, postResetPassword } from './api';
import { getSecondsSinceEpoch } from '../utils/utils';

interface Token {
  id: number;
  iat: number;
  exp: number;
}

class AuthService {
  tokenKey = 'token';

  async login(username: string, password: string) {
    const res = await postAuth({ identifier: username, password });
    const { jwt, user } = res.data;

    this.setToken(jwt);

    return { token: jwt, user };
  }

  async handleMicrosoftCallback(accessToken: string) {
    const res = await getTokenFromCallback(accessToken);
    const { jwt, user } = res.data;

    this.setToken(jwt);

    return { token: jwt, user };
  }

  setToken(token: string) {
    return localStorage.setItem(this.tokenKey, token);
  }

  getToken() {
    return localStorage.getItem(this.tokenKey);
  }

  logout() {
    localStorage.clear();
  }

  async forgotPassword(email: string) {
    return await postForgotPassword(email);
  }

  async resetPassword(code: string, password: string, passwordConfirmation: string) {
    return await postResetPassword(code, password, passwordConfirmation);
  }

  isTokenValid() {
    const token = this.getToken();

    if (!token) {
      return false;
    }

    try {
      const decodedToken: Token = jwt_decode(token);
      const secondsSinceEpoch = getSecondsSinceEpoch();
      const validityPeriod = 1 * 60 ** 2; // hours in seconds

      return decodedToken.exp - secondsSinceEpoch > validityPeriod;
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e.message);
      return false;
    }
  }
}

export default new AuthService();
