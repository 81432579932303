import authService from '../../services/auth';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

interface AuthMicrosoftFunctionProps {
  loginSuccess: () => void;
}

const AuthMicrosoftFunction: React.FC<AuthMicrosoftFunctionProps> = ({ loginSuccess }) => {
  const location = useLocation();

  useEffect(() => {
    // Extract the access_token query parameter
    const queryParams = new URLSearchParams(location.search);
    const accessToken = queryParams.get('access_token');

    if (!accessToken || !authService) {
      return;
    }

    authService.handleMicrosoftCallback(accessToken).then(() => loginSuccess());
  }, [location.search, loginSuccess]);

  return null;
};

export default AuthMicrosoftFunction;
