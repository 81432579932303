import { useLocation, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import orderLinkLogoExpanded from '../../assets/logo-expanded.svg';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import authService from '../../services/auth';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormHelperText, Stack } from '@mui/material';
import { getErrorMessage } from 'utils/api.utils';
import React from 'react';

interface FormValues {
  password: string;
  confirmPassword: string;
}

const ResetPasswordComponent = () => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const schema = yup.object().shape({
    password: yup.string().required(t('passwordRequired', 'Password is required')),
    confirmPassword: yup.string().required(t('passwordRequired', 'Password is required')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const onSubmit = async (data: FormValues) => {
    const { password, confirmPassword } = data;

    try {
      const code = search.split('=')[1];

      await authService.resetPassword(code, password, confirmPassword);
      navigate('/login');
    } catch (error) {
      const message = getErrorMessage(error);
      setError('password', { message });
    }
  };

  return (
    <Container maxWidth="xs" sx={{ height: '100%' }}>
      <Stack pt={'25vh'} direction={'column'} justifyContent={'center'}>
        <Box textAlign={'center'} height={'4rem'} pl={'1.5rem'} mb={1}>
          <img width={'100%'} height={'100%'} src={orderLinkLogoExpanded} alt="Company Logo" />
        </Box>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box textAlign={'center'} my={'1rem'}>
            <h1>Reset password</h1>
          </Box>
          <TextField
            required
            fullWidth
            id="password"
            sx={{ my: 1 }}
            label={t('password', 'Password')}
            type="password"
            {...register('password')}
            autoComplete="password"
            autoFocus
            className="login"
          />
          {errors.password && <FormHelperText error={true}>{errors.password.message}</FormHelperText>}
          <TextField
            required
            fullWidth
            {...register('confirmPassword')}
            label={t('confirmPassword', 'Confirm Password')}
            type="password"
            id="confirmPassword"
            sx={{ my: 1 }}
            autoComplete="confirm-password"
            className="login"
          />
          {errors.confirmPassword && <FormHelperText error={true}>{errors.confirmPassword.message}</FormHelperText>}

          <Box textAlign={'center'}>
            <Button type="submit" color={'secondary'} variant="contained" sx={{ my: 1 }} fullWidth={true}>
              {t('changePassword', 'Change Password')}
            </Button>
            <Link
              className="login-link"
              onClick={() => {
                navigate('/login');
              }}
              variant="body2"
            >
              {t('readyToSignIn', 'Ready to sign in?')}
            </Link>
          </Box>
        </Box>
      </Stack>
    </Container>
  );
};
export default ResetPasswordComponent;
