import { useSyncExternalStore } from 'react';
import { floored } from '../utils/utils';

const onWindowSizeChange = (onChange: () => void) => {
  window.addEventListener('resize', onChange);
  return () => window.removeEventListener('resize', onChange);
};

const getWindowHeightSnapshot = (window: Window) => {
  return window.innerHeight;
};

const getWindowWidthSnapshot = (window: Window) => {
  return window.innerWidth;
};

export function useWindowHeight() {
  // noinspection UnnecessaryLocalVariableJS
  const windowHeight = useSyncExternalStore<number>(onWindowSizeChange, () =>
    floored(getWindowHeightSnapshot(window), 10)
  );

  return windowHeight;
}

export function useWindowWidth() {
  // noinspection UnnecessaryLocalVariableJS
  const windowWidth = useSyncExternalStore<number>(onWindowSizeChange, () =>
    floored(getWindowWidthSnapshot(window), 10)
  );

  return windowWidth;
}
