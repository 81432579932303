import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import LocizeBackend from 'i18next-locize-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { locizePlugin } from 'locize';
import { DateTime } from 'luxon';
import { currentEnv } from './config';

const supportedLanguages = Object.keys(currentEnv.availableLanguages);

const isProduction = import.meta.env.NODE_ENV === 'production';

const backendOptions = {
  projectId: import.meta.env.VITE_LOCIZE_PROJECTID,
  apiKey: import.meta.env.VITE_LOCIZE_APIKEY,
  referenceLng: import.meta.env.VITE_LOCIZE_REFLNG,
  version: import.meta.env.VITE_LOCIZE_VERSION,
};

export const initLocize = () => {
  i18n
    .use(locizePlugin)
    .use(ChainedBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: false,
      fallbackLng: 'en',
      supportedLngs: supportedLanguages,
      interpolation: {
        escapeValue: false,
        format: (value, format, lang) => {
          if (value instanceof Date) {
            return DateTime.fromJSDate(value).setLocale(lang).toLocaleString(DateTime[format]);
          }
          return value;
        },
      },
      backend: {
        backends: [LocizeBackend, resourcesToBackend((lng, ns) => import(`./locales/${lng}/${ns}.json`))],
        backendOptions: [backendOptions],
      },
      saveMissing: !isProduction,
    });
};
