import type { Customer, OrderItem, Queue, User, ValidationMessage } from '../types';
import { Severity } from '../types/enums.ts';

export const hasError = (errors: Record<string, any>, field: string) => errors[field] !== undefined;

export function capitalize(s: string) {
  if (s.length === 0) return '';

  return s[0].toUpperCase() + s.slice(1).toLowerCase();
}

export function splitSnakeCase(s: string | undefined) {
  return capitalize((s || '').split('_').join(' '));
}

export function getFirstNLetters(s: string, n: number) {
  return s.slice(0, n);
}

export const getCustomerName = (customer: Partial<Customer>) => {
  let str = '';
  if (customer?.legalName) {
    str += customer.legalName.replace(/\n/, ' ');
  }
  if (customer?.customerNumber) {
    str += ` (${customer.customerNumber})`;
  }
  return str;
};

export const findQueueById = (id: number | null, queues: Queue[]) => queues.find((queue) => queue.id === id) || null;

export const getMaxPositionReference = (orderItems: OrderItem[]) =>
  Math.max(
    0,
    ...orderItems.map((orderItem) => parseInt(orderItem.positionReference, 10)).filter((value) => !Number.isNaN(value))
  );

export const calculateNewPosRef = (positionReference: number) => {
  if (positionReference % 10 === 0) {
    return positionReference + 10;
  }

  return Math.ceil(positionReference / 10) * 10;
};

export const getStartAndLimit = (startRow: number, endRow: number, prependedItemsLength: number) => {
  const start = Math.max(0, startRow - prependedItemsLength);
  const limit = endRow - start - prependedItemsLength; // Assume never have more prepended items than page size

  return { start, limit };
};

export const floored = (value: number, to = 100) => Math.floor(value / to) * to;

export function sanitizeFilename(input: string) {
  const illegalRe = /[\/\?<>\\:\*\|"]/g;
  const controlRe = /[\x00-\x1f\x80-\x9f]/g; // eslint-disable-line no-control-regex
  const reservedRe = /^\.+$/;
  const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;
  const windowsTrailingRe = /[\. ]+$/;
  const maxFilenameLength = 200;

  return input
    .replace(illegalRe, '')
    .replace(controlRe, '')
    .replace(reservedRe, '')
    .replace(windowsReservedRe, '')
    .replace(windowsTrailingRe, '')
    .slice(0, maxFilenameLength);
}

export function downloadBlob(blob: Blob, filename: string) {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;

  // Append the link to the document and simulate a click to start the download
  document.body.appendChild(link);
  link.click();

  window.URL.revokeObjectURL(link.href);
  document.body.removeChild(link);
}

export const getKeys = (obj: object | undefined) => Object.keys(obj || {});

export const splitAttachmentUrls = (str: string) => str.split('|').filter(Boolean);

export const getExtensionFromUrl = (str: string) => str.split('.').pop() || '';

export const getNameAndExtensionFromUrl = (url: string) => {
  const filename = url.split('/').at(-1) as string;
  const [name, extension] = filename.split('.') || [];

  return { name, extension };
};

export const isCharacterLetter = (character: string | null | undefined) => {
  if (character === null || character === undefined) {
    return false;
  }

  return /[a-z]/i.test(character);
};

export const areSetsEqual = <T>(setA: Set<T>, setB: Set<T>) =>
  setA.size === setB.size && Array.from(setA).every((value) => setB.has(value));

export function mapAssigneeToOption(users: User[]) {
  return users
    .map((user) => ({
      value: user.id,
      label: user.username,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

export const isSupportedMimeType = (mimeType: string) => {
  const supportedMimeTypes = ['image/png', 'image/jpeg', 'application/pdf'];

  return supportedMimeTypes.includes(mimeType);
};

export const getSecondsSinceEpoch = () => Math.round(new Date().getTime() / 1000);

export function filterValidationMessagesByFieldAndSeverity(
  validationMessages: ValidationMessage[],
  field: string | undefined,
  severity: Severity
) {
  return validationMessages.filter((message) => message.field === field && message.severity === severity);
}

export function getValidationMessageFieldStatus(validationMessages?: ValidationMessage[], field?: string) {
  validationMessages = validationMessages || [];
  const severities = [Severity.Error, Severity.Warning, Severity.Suggestion];

  for (const severity of severities) {
    const messages = filterValidationMessagesByFieldAndSeverity(validationMessages, field, severity);
    if (messages.length > 0) {
      return severity;
    }
  }

  return null;
}

export function getValidationMessageFieldColor(validationMessages?: ValidationMessage[], field?: string) {
  validationMessages = validationMessages || [];
  const severities: [Severity, 'error' | 'warning' | 'info'][] = [
    [Severity.Error, 'error'],
    [Severity.Warning, 'warning'],
    [Severity.Suggestion, 'info'],
  ];

  for (const [severity, color] of severities) {
    const messages = filterValidationMessagesByFieldAndSeverity(validationMessages, field, severity);
    if (messages.length > 0) {
      return color;
    }
  }

  return undefined;
}
