import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../../context/auth-context';

function HomeRedirect() {
  const { isLoggedIn, user } = useContext(AuthContext);

  if (isLoggedIn === null) {
    // User has not yet been authenticated - we don't know if they are logged in or not
    // TODO: Could show a loading spinner here
    return null;
  }

  if (isLoggedIn) {
    if (user === null) {
      // We are waiting for the user to be loaded
      return null;
    }

    return <Navigate to={'/order-requests'} replace />;
  }

  // User is not logged in - redirect to login page
  return <Navigate to={'/login'} replace />;
}

export default HomeRedirect;
