export const createAPISearchFilter = (searchFilter: string, columnsToSearch: string[]) => {
  let searchFilterQuery = ``;
  if (searchFilter) {
    if (columnsToSearch.length === 1) {
      searchFilterQuery = `&filters${columnsToSearch[0]}[$containsi]=${searchFilter}`;
    } else {
      columnsToSearch.forEach((column: string, index: number) => {
        searchFilterQuery += `&filters[$or][${index}]${column}[$containsi]=${searchFilter}`;
      });
    }
  }

  return searchFilterQuery;
};
