import React, { useCallback, useContext, useMemo } from 'react';
import { useMemoCompare } from '../../hooks/use-memo-compare';

const getSeparator = (locale: string, type: 'decimal' | 'group') => {
  const number = 1000.1;

  return new Intl.NumberFormat(locale).formatToParts(number).find((part) => part.type === type)?.value ?? '';
};

interface IntlConfig {
  locale: string;
  groupSeparator: string;
  decimalSeparator: string;
}

const IntlProviderContext = React.createContext<IntlConfig>(null as unknown as IntlConfig);

interface IntlProviderProps {
  locale: string; //TODO: Only allow valid locales
  defaultLocale?: string;
}

export const IntlProvider = ({
  children,
  locale,
  defaultLocale = 'en',
}: React.PropsWithChildren<IntlProviderProps>) => {
  // TODO: Allow default options for IntlProvider
  const _locale = locale || defaultLocale;

  const config = useMemo<IntlConfig>(() => {
    const groupSeparator = getSeparator(_locale, 'group');
    const decimalSeparator = getSeparator(_locale, 'decimal');

    return { locale: _locale, groupSeparator, decimalSeparator };
  }, [_locale]);

  return <IntlProviderContext.Provider value={config}>{children}</IntlProviderContext.Provider>;
};

export const useIntl = () => {
  return useContext(IntlProviderContext);
};

interface IntlNumberFormatterProps extends Intl.NumberFormatOptions {
  value: number | bigint;
}

export const IntlNumberFormatter = ({ value, ...options }: IntlNumberFormatterProps) => {
  const { locale } = useIntl();
  const formatter = new Intl.NumberFormat(locale, options);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (value === null || value === undefined || value === '') {
    return null;
  }

  return <React.Fragment>{formatter.format(value)}</React.Fragment>;
};

export const useIntlNumberFormatter = (options?: Intl.NumberFormatOptions) => {
  const memoizedOptions = useMemoCompare(options);
  const { locale } = useIntl();

  // noinspection UnnecessaryLocalVariableJS
  const fn = useCallback(
    (value: number | bigint) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (value === null || value === undefined || value === '') {
        return '';
      }

      const formatter = new Intl.NumberFormat(locale, memoizedOptions);

      return formatter.format(value);
    },
    [locale, memoizedOptions]
  );

  return fn;
};
