import React from 'react';
import Helmet from 'react-helmet';

type BrowserTitleProps = {
  title: string;
};

function BrowserTitle({ title, children }: React.PropsWithChildren<BrowserTitleProps>) {
  return (
    <Helmet>
      <title>{title}</title>
      {children}
    </Helmet>
  );
}

export default BrowserTitle;
