export const EventEmitter = {
  _events: {} as Record<string, Array<(data: unknown) => void>>,

  dispatch(event: string, data: any) {
    if (!this._events[event]) return;
    this._events[event].forEach((callback) => callback(data));
  },

  subscribe(event: string, callback: (data: any) => void) {
    if (!this._events[event]) this._events[event] = [];
    this._events[event].push(callback);
  },

  unsubscribe(event: string, callback: (data: any) => void) {
    if (!this._events[event]) return;
    const index = this._events[event].findIndex((event) => event === callback);
    if (index !== -1) {
      this._events[event].splice(index, 1);
    }
  },
};
